import React from "react";
import {
    BrowserRouter as Router,
    // Navigate,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";

import Home from "./Home/Home.js";
import Core from "./Core/Core.js";
import Tool from "./Tool/Tool.js";
import About from "./About/About.js"

function Components() {
    return (
        <Router>
            <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/core" element={<Core />} />
                <Route path="/tools" element={<Tool />} />
                <Route path="/about" element={<About />} />
                <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
        </Router>
    );
}

export default Components;