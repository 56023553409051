import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaTimes, FaBars, FaHome, FaCogs, FaTools, FaUserPlus } from 'react-icons/fa';

import './Navbar.css';

/**
 * Optional props:
 * @param {Object} props
 * @param {String} props.textColor - Default text/icon color for non-active links.
 * @param {String} props.hoverBgColor - Background color on hover for non-active links.
 * @param {String} props.activeColor - Color for an active link (Tailwind sky-400 by default).
 * @param {Object} props.navbarBackgroundStyle - Additional inline styles for sideNav background.
 */
function Navbar({
    textColor = '#ffffff',
    hoverBgColor = '#f0f0f0',
    activeColor = '#00BFA6', // Tailwind sky-400
    navbarBackgroundStyle = {}
}) {
    // Controls the expand/collapse state
    const [navExpanded, setNavExpanded] = useState(false);

    const toggleNav = () => {
        setNavExpanded(prev => !prev);
    };

    return (
        <aside
            className={`sideNav ${navExpanded ? 'expanded' : 'collapsed'}`}
            style={{
                ...navbarBackgroundStyle, // Allows overriding or extending the background styles
            }}
        >
            {/* Toggle button for expanding/collapsing the sidebar */}
            <button className="toggleButton" onClick={toggleNav}>
                {navExpanded ? <FaTimes /> : <FaBars />}
            </button>

            {/* Logo / Brand */}
            <div className="brandContainer flex items-center justify-center">
            {navExpanded ? (
                <img
                src="/logo.svg"
                alt="Desync Logo"
                className="brandExpanded h-10 w-auto"
                />
            ) : (
                <img
                src="/square_logo_navbar.png"
                alt="Desync Icon"
                className="brandCollapsed h-11 w-auto"
                />
            )}
            </div>


            {/* Navigation Links */}
            <div className="navLinks">
                {/* --- HOME --- */}
                <NavLink
                    to="/home"
                    className="navItem"
                    style={({ isActive }) => ({
                        color: isActive ? activeColor : textColor,
                        backgroundColor: 'transparent'
                    })}
                    onMouseEnter={(e) => {
                        if (!e.currentTarget.getAttribute('aria-current')) {
                            e.currentTarget.style.backgroundColor = hoverBgColor;
                            e.currentTarget.style.color = '#000000';
                        }
                    }}
                    onMouseLeave={(e) => {
                        if (!e.currentTarget.getAttribute('aria-current')) {
                            e.currentTarget.style.backgroundColor = 'transparent';
                            e.currentTarget.style.color = textColor;
                        }
                    }}
                >
                    <FaHome
                        className="navIcon"
                        style={({ isActive }) => ({
                            color: isActive ? activeColor : textColor
                        })}
                    />
                    {navExpanded && <span>Home</span>}
                </NavLink>

                {/* --- DESYNC CORE --- */}
                <NavLink
                    to="/core"
                    className="navItem"
                    style={({ isActive }) => ({
                        color: isActive ? activeColor : textColor,
                        backgroundColor: 'transparent'
                    })}
                    onMouseEnter={(e) => {
                        if (!e.currentTarget.getAttribute('aria-current')) {
                            e.currentTarget.style.backgroundColor = hoverBgColor;
                            e.currentTarget.style.color = '#000000';
                        }
                    }}
                    onMouseLeave={(e) => {
                        if (!e.currentTarget.getAttribute('aria-current')) {
                            e.currentTarget.style.backgroundColor = 'transparent';
                            e.currentTarget.style.color = textColor;
                        }
                    }}
                >
                    <FaCogs
                        className="navIcon"
                        style={({ isActive }) => ({
                            color: isActive ? activeColor : textColor
                        })}
                    />
                    {navExpanded && <span>Desync Core</span>}
                </NavLink>

                {/* --- DESYNC TOOLS --- */}
                <NavLink
                    to="/tools"
                    className="navItem"
                    style={({ isActive }) => ({
                        color: isActive ? activeColor : textColor,
                        backgroundColor: 'transparent'
                    })}
                    onMouseEnter={(e) => {
                        if (!e.currentTarget.getAttribute('aria-current')) {
                            e.currentTarget.style.backgroundColor = hoverBgColor;
                            e.currentTarget.style.color = '#000000';
                        }
                    }}
                    onMouseLeave={(e) => {
                        if (!e.currentTarget.getAttribute('aria-current')) {
                            e.currentTarget.style.backgroundColor = 'transparent';
                            e.currentTarget.style.color = textColor;
                        }
                    }}
                >
                    <FaTools
                        className="navIcon"
                        style={({ isActive }) => ({
                            color: isActive ? activeColor : textColor
                        })}
                    />
                    {navExpanded && <span>Desync Tools</span>}
                </NavLink>

                {/* --- SIGN UP (external link) --- */}
                <NavLink
                    to="https://beta.desync.ai/home"
                    target="_blank"
                    rel="noreferrer"
                    className="navItem"
                    style={({ isActive }) => ({
                        color: isActive ? activeColor : textColor,
                        backgroundColor: 'transparent'
                    })}
                    onMouseEnter={(e) => {
                        if (!e.currentTarget.getAttribute('aria-current')) {
                            e.currentTarget.style.backgroundColor = hoverBgColor;
                            e.currentTarget.style.color = '#000000';
                        }
                    }}
                    onMouseLeave={(e) => {
                        if (!e.currentTarget.getAttribute('aria-current')) {
                            e.currentTarget.style.backgroundColor = 'transparent';
                            e.currentTarget.style.color = textColor;
                        }
                    }}
                >
                    <FaUserPlus
                        className="navIcon"
                        style={({ isActive }) => ({
                            color: isActive ? activeColor : textColor
                        })}
                    />
                    {navExpanded && <span>Sign Up</span>}
                </NavLink>
            </div>
        </aside>
    );
}

export default Navbar;
