// Core.js
import React, { useState } from 'react';
import Navbar from '../ReusableComponents/Navbar';

// Icons
import {
  FaSearch,
  FaShieldAlt,
  FaDollarSign,
  FaCopy,
  FaChevronDown,
  FaChevronUp,
  FaCogs,
} from 'react-icons/fa';
import { SiPython } from 'react-icons/si';
import { FiExternalLink } from 'react-icons/fi';

// Collapsible function data
const functionData = [
  {
    name: 'search(url)',
    description: 'Perform a stealth search (cost 10 credits). Bypasses CAPTCHAs more reliably.',
    oneLineUsage: 'client.search("https://example.com")',
    expandedCode: `# Stealth search
page_data = client.search("https://example.com")
if page_data.success:
    print(page_data.text_content[:200])  # first 200 chars`,
  },
  {
    name: 'search(url, search_type="test_search")',
    description: 'A cheaper test search (1 credit). Good for quick checks, less stealth though.',
    oneLineUsage: 'client.search("https://example.com", search_type="test_search")',
    expandedCode: `# Test search
test_data = client.search("https://www.python.org", search_type="test_search")
if test_data.success:
    print("Test search result length:", len(test_data.text_content))`,
  },
  {
    name: 'list_available()',
    description: 'Retrieve minimal records for each past search (IDs, URLs, timestamps).',
    oneLineUsage: 'client.list_available()',
    expandedCode: `records = client.list_available()
for r in records:
    print(r.id, r.url, r.search_type, r.created_at)`,
  },
  {
    name: 'pull_data(...)',
    description: 'Fetch full text, HTML, and links from a stored record or filtered by URL.',
    oneLineUsage: 'client.pull_data(record_id=123)',
    expandedCode: `details = client.pull_data(record_id=123)
if details and details[0].success:
    print("HTML length:", len(details[0].html_content))`,
  },
  {
    name: 'crawl(...)',
    description: 'Recursively gather internal URLs from a seed page. (Beware credit usage if big!)',
    oneLineUsage: 'crawl(client, "https://example.com")',
    expandedCode: `visited = set()

def crawl(client, url):
    if url in visited:
        return
    visited.add(url)

    page_data = client.search(url)  # stealth by default
    print("Scraped:", url, "- Found", len(page_data.internal_links), "links")

    for link in page_data.internal_links:
        if link not in visited:
            crawl(client, link)`,
  },
];

// Simple color-highlighting with naive regex
function highlightCode(code) {
  return code
    .replace(
      /\b(import|from|def|class|if|for|return|print|client|crawl|elif|else)\b/g,
      '<span class="text-blue-300">$1</span>'
    );
}

// Code snippet component
function CodeSnippet({ code }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const highlighted = highlightCode(code);

  return (
    <div className="relative bg-[#282C34] rounded-2xl shadow-inner p-4">
      {/* Python icon in corner */}
      <div className="absolute top-2 left-2" style={{ color: '#306998' }}>
        <SiPython size={22} />
      </div>
      {/* Copy button */}
      <button
        onClick={handleCopy}
        className="absolute top-2 right-2 text-xs px-2 py-1 
                  bg-[#3C4043] text-gray-200 hover:bg-blue-600 hover:text-white 
                  rounded transition-colors"
      >
        {copied ? 'Copied!' : <FaCopy size={12} />}
      </button>

      {/* Highlighted code block */}
      <pre className="mt-6 text-base leading-relaxed text-gray-100 whitespace-pre overflow-auto">
        <code dangerouslySetInnerHTML={{ __html: highlighted }} />
      </pre>
    </div>
  );
}

export default function Core() {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const toggleExpand = (idx) => {
    setExpandedIndex((prev) => (prev === idx ? null : idx));
  };

  return (
    <div className="bg-[#0B0F10] min-h-screen text-gray-100 flex">
      {/* Sidebar (optional) */}
      <div>
        <Navbar
          textColor="#fff"
          accentColor="#3498eb"
          navbarBackgroundStyle={{ backgroundColor: '#0E1112' }}
        />
      </div>

      {/* MAIN CONTENT */}
      <div className="flex-1 px-6 py-10 md:px-12 md:py-12">

        {/* TOP HEADER with "Desync Core" + Motto */}
        <header
          className="
            bg-[#11181D] rounded-2xl shadow-neomorphic p-6 mb-10 
            flex flex-col md:flex-row items-start md:items-center justify-between
          "
        >
          {/* Left Section: Icon + Title/Subheading */}
          <div className="flex items-center gap-4">
            <FaCogs size={60} className="text-blue-500" />
            <div>
              <h1 className="text-3xl md:text-4xl font-bold text-blue-400 mb-1">
                Desync Core
              </h1>
              <h2 className="text-base md:text-lg text-gray-300 italic">
                The API to the Internet
              </h2>
            </div>
          </div>

          {/* Right Section: Motto */}
          <div className="text-sm md:text-base text-blue-200 mt-4 md:mt-0 md:ml-4 max-w-sm text-left md:text-right font-semibold">
            The easiest way to scrape and retrieve web data without heavy rate limits or detection.
          </div>
        </header>

        {/* ROW 1: Project Desc & Installation */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          {/* Project Description */}
          <div
            className="
              bg-[#11181D] p-6 rounded-2xl shadow-neomorphic 
              transition group hover:shadow-neomorphic-inset
            "
          >
            <h2 className="text-xl font-bold mb-3 text-blue-300">
              Project Description
            </h2>
            <p className="text-base text-gray-200 mb-2 leading-relaxed">
              <strong>Desync Search</strong> helps you scrape and retrieve web data with
              minimal detection or rate limits.
            </p>
            <p className="text-base text-gray-200 leading-relaxed">
              Easily run <strong>human-like</strong> visits, avoid CAPTCHAs, and store
              search results for future retrieval — <em>pay only for the searches you make.</em>
            </p>
          </div>

          {/* Installation */}
          <div
            className="
              bg-[#11181D] p-6 rounded-2xl shadow-neomorphic 
              transition group hover:shadow-neomorphic-inset
            "
          >
            <h2 className="text-xl font-bold mb-3 text-blue-300">
              Installation
            </h2>
            <p className="text-base text-gray-200 mb-3 leading-relaxed">Install via PyPI:</p>
            <CodeSnippet code="pip install desync_search" />
          </div>
        </div>

        {/* ROW 2: Bullet Points => Stealth, Pricing, 1k Free */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-10">
          <div
            className="
              relative group shadow-neomorphic rounded-2xl p-6 transition 
              hover:shadow-neomorphic-inset flex flex-col items-start 
            "
          >
            <div className="flex items-center gap-3 mb-2">
              <FaSearch size={40} className="text-blue-400" />
              <h2 className="font-extrabold text-lg text-blue-300">Stealth Search</h2>
            </div>
            <p className="text-base text-gray-200 leading-relaxed">
              “Human-like” visits to bypass CAPTCHAs.
            </p>
          </div>

          <div
            className="
              relative group shadow-neomorphic rounded-2xl p-6 transition 
              hover:shadow-neomorphic-inset flex flex-col items-start
            "
          >
            <div className="flex items-center gap-3 mb-2">
              <FaDollarSign size={40} className="text-blue-400" />
              <h2 className="font-extrabold text-lg text-blue-300">Competitive Pricing</h2>
            </div>
            <p className="text-base text-gray-200 leading-relaxed">
              Pay only for usage—no forced monthly fees.
            </p>
          </div>

          <div
            className="
              relative group shadow-neomorphic rounded-2xl p-6 transition 
              hover:shadow-neomorphic-inset flex flex-col items-start
            "
          >
            <div className="flex items-center gap-3 mb-2">
              <FaShieldAlt size={40} className="text-blue-400" />
              <h2 className="font-extrabold text-lg text-blue-300">First 1,000 Searches Free</h2>
            </div>
            <p className="text-base text-gray-200 leading-relaxed">
              Get started risk-free.
            </p>
          </div>
        </div>

        {/* ROW 3: Basic Usage & Primary Functions */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          {/* Basic Usage */}
          <div
            className="
              bg-[#11181D] p-6 rounded-2xl shadow-neomorphic 
              transition group hover:shadow-neomorphic-inset
            "
          >
            <h2 className="text-xl font-bold mb-4 text-blue-300">Basic Usage</h2>
            <p className="text-base text-gray-200 mb-4 leading-relaxed">
              Grab your API key from an environment variable or config, then create a client:
            </p>
            <CodeSnippet
              code={`import os
from desync_search.core import DesyncClient

user_api_key = os.environ.get("DESYNC_API_KEY", "")
client = DesyncClient(user_api_key)`}
            />

            {/* Full Docs Button */}
            <div className="mt-6 flex justify-center">
              <a
                href="https://pypi.org/project/desync-search/"
                target="_blank"
                rel="noopener noreferrer"
                className="
                  relative inline-flex items-center px-5 py-3 
                  text-blue-300 border border-blue-300 rounded-xl 
                  hover:bg-blue-300 hover:text-[#0B0F10] 
                  transition-colors font-semibold
                "
              >
                View Full Documentation in PyPI!
                <FiExternalLink className="ml-2" />
              </a>
            </div>
          </div>

          {/* Primary Functions (Collapsible) */}
          <div
            className="
              bg-[#11181D] p-6 rounded-2xl shadow-neomorphic 
              transition group hover:shadow-neomorphic-inset
            "
          >
            <h2 className="font-bold text-xl mb-4 text-blue-300">Primary Functions</h2>
            <p className="text-base text-gray-200 mb-4 leading-relaxed">
              Click each function to see details.
            </p>

            <div className="space-y-3">
              {functionData.map((func, idx) => {
                const isOpen = expandedIndex === idx;
                return (
                  <div
                    key={idx}
                    className="
                      border border-gray-700 rounded-xl p-4 
                      transition hover:bg-[#1a252d]
                    "
                  >
                    <button
                      onClick={() => toggleExpand(idx)}
                      className="w-full text-left flex justify-between items-center"
                    >
                      <div>
                        <p className="font-semibold text-blue-300 text-md">{func.name}</p>
                        <p className="text-sm text-gray-300">
                          {func.description}
                        </p>
                        <p className="text-sm text-gray-400 mt-1">
                          <strong>One-liner:</strong> <em>{func.oneLineUsage}</em>
                        </p>
                      </div>
                      <div className="text-gray-300">
                        {isOpen ? <FaChevronUp size={14} /> : <FaChevronDown size={14} />}
                      </div>
                    </button>
                    {isOpen && func.expandedCode && (
                      <div className="mt-3">
                        <CodeSnippet code={func.expandedCode} />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Customized bigger shadows for stronger neumorphic feel */}
      <style jsx>{`
        .shadow-neomorphic {
          box-shadow:
            12px 12px 30px rgba(0,0,0,0.5),
            -12px -12px 30px rgba(255,255,255,0.03);
        }
        .shadow-neomorphic-inset {
          box-shadow:
            inset 12px 12px 30px rgba(0,0,0,0.5),
            inset -12px -12px 30px rgba(255,255,255,0.03);
        }
      `}</style>
    </div>
  );
}
