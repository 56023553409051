// toolsData.js

const toolsData = [
    {
        id: 1,
        name: 'Together.ai',
        description: 'Easy LLM calls with the Together.ai platform.',
        author: 'Desync AI',
        github: 'https://github.com/example/together-ai',
        yt: 'https://youtube.com/together-ai-demo',
        tags: ['agents', 'inference', 'api'],
    },
    {
        id: 2,
        name: 'LanceDB',
        description: 'A vector database designed for ML workflows.',
        author: 'Desync AI',
        github: 'https://github.com/example/lancedb',
        yt: 'https://youtube.com/lancedb-overview',
        tags: ['vector-db', 'open-source'],
    },
    {
        id: 3,
        name: 'OpenAI Integrator',
        description: 'Quickly connect to OpenAI models for text or embedding tasks.',
        author: 'Desync AI',
        github: 'https://github.com/example/openai-integrator',
        yt: 'https://youtube.com/openai-integrator',
        tags: ['inference', 'api', 'agents'],
    },
    {
        id: 4,
        name: 'Anthropic Helper',
        description: 'Seamless interface with Anthropic’s Claude models.',
        author: 'Desync AI',
        github: 'https://github.com/example/anthropic-helper',
        yt: 'https://youtube.com/anthropic-helper',
        tags: ['inference', 'api'],
    },
    {
        id: 5,
        name: 'Complex Agent',
        description: 'Advanced agent with RAG capabilities and chain-of-thought prompts.',
        author: 'Desync AI',
        github: 'https://github.com/example/complex-agent',
        yt: 'https://youtube.com/complex-agent-demo',
        tags: ['agents', 'rag', 'automation'],
    },
    {
        id: 6,
        name: 'SQL Store',
        description: 'Store your data in a SQL backend with easy retrieval and queries.',
        author: 'Desync AI',
        github: 'https://github.com/example/sql-store',
        yt: 'https://youtube.com/sql-store-tutorial',
        tags: ['sql-db', 'relational-db'],
    },
    {
        id: 7,
        name: 'Data Normalizer',
        description: 'Convert and normalize data between CSV, JSON, and more.',
        author: 'Desync AI',
        github: 'https://github.com/example/data-normalizer',
        yt: 'https://youtube.com/data-normalizer-intro',
        tags: ['csv', 'json', 'automation'],
    },
];

export default toolsData;
