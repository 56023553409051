// Tool.js

import React, { useState } from 'react';
import Navbar from '../ReusableComponents/Navbar';
import {
  FaTools,
  FaGithub,
  FaYoutube,
  FaRobot,
  FaDatabase,
  FaBrain,
  FaBoxOpen,
  FaCogs,
} from "react-icons/fa";
import { AiFillCode, AiOutlineApi } from "react-icons/ai";
import { RiFileExcel2Line, RiStackFill } from "react-icons/ri";
import { SiOpensourceinitiative, SiOpenbsd, SiMysql, SiPython } from "react-icons/si";
import { GiBrain } from "react-icons/gi";

// Import your 7 tools from a separate file
import toolsData from './toolsData';

// TAGS for filtering (with icons)
const filterTags = [
  { name: 'vector-db', label: 'Vector DB', icon: <RiStackFill size={18} /> },
  { name: 'graph-db', label: 'Graph DB', icon: <FaBrain size={18} /> },
  { name: 'relational-db', label: 'Relational DB', icon: <FaDatabase size={18} /> },
  { name: 'sql-db', label: 'SQL DB', icon: <SiMysql size={18} /> },
  { name: 'csv', label: 'CSV', icon: <RiFileExcel2Line size={18} /> },
  { name: 'json', label: 'JSON', icon: <AiFillCode size={18} /> },
  { name: 'rag', label: 'RAG', icon: <GiBrain size={18} /> },
  { name: 'agents', label: 'Agents', icon: <FaRobot size={18} /> },
  { name: 'inference', label: 'Inference', icon: <GiBrain size={18} /> },
  { name: 'open-source', label: 'Open Source', icon: <SiOpensourceinitiative size={18} /> },
  { name: 'closed-source', label: 'Closed Src', icon: <SiOpenbsd size={18} /> },
  { name: 'automation', label: 'Automation', icon: <FaCogs size={18} /> },
  { name: 'packages', label: 'Packages', icon: <FaBoxOpen size={18} /> },
  { name: 'api', label: 'API', icon: <AiOutlineApi size={18} /> },
  { name: 'python-tools', label: 'Python Tools', icon: <SiPython size={18} /> },
];

function Tool() {
  const [searchText, setSearchText] = useState('');
  const [selectedTag, setSelectedTag] = useState('');

  // Toggle the selected tag (single-select)
  const handleTagClick = (tagName) => {
    setSelectedTag((prev) => (prev === tagName ? '' : tagName));
  };

  // Filter by name + selectedTag
  const filteredTools = toolsData.filter((tool) => {
    const matchesName = tool.name.toLowerCase().includes(searchText.toLowerCase());
    const matchesTag = !selectedTag || (tool.tags && tool.tags.includes(selectedTag));
    return matchesName && matchesTag;
  });

  return (
    <div className="min-h-screen flex bg-gradient-to-b from-[#0B0F10] to-[#05080A] text-[#EAEAEA]">
      {/* Sidebar */}
      <div className="bg-[#0E1112] flex-shrink-0">
        <Navbar textColor="#ffffff" accentColor="#00BFA6" />
      </div>

      {/* Main container */}
      <main className="flex-1 container mx-auto px-4 py-8 md:py-10">

        {/* HERO SECTION */}
        <section className="bg-[#131819] rounded-xl p-6 mb-6 shadow-neomorphic relative overflow-hidden">
          <div className="flex flex-col gap-2">
            {/* Title + Icon */}
            <div className="flex items-center gap-3 mb-2">
              <FaTools size={60} className="text-[#ffaa5f]" />
              <h1 className="text-4xl font-bold text-[#ffaa5f]">Desync Tools - Coming Soon!</h1>
            </div>
            {/* Subtext */}
            <p className="text-md md:text-lg opacity-75">
              <span className="text-cyan-400 font-semibold">
                Desync Core gets you the data.
              </span>{' '}
              <span className="text-[#ffaa5f] font-semibold">
                Desync Tools let you manipulate the data.
              </span>
            </p>
            <p className="text-sm md:text-base opacity-75 mt-3">
              We built these tools to be as simple as possible to let any user integrate them.
              YouTube Tutorials are on their way.
            </p>
          </div>

          {/* CTA Buttons - flex container that can wrap on small screens */}
          <div className="mt-4 flex flex-wrap gap-5 justify-end">
            {/* Check Out Core (Aqua) */}
            <a
              href="/core"
              className="
                min-w-[140px] rounded-md px-4 py-2 text-sm font-semibold 
                text-white shadow-neomorphic border border-[#0a7687]
                bg-transparent
                transition-all duration-300
                hover:scale-105
                hover:border-cyan-300 hover:bg-[#0f919f] text-center
              "
            >
              Check Out Core
            </a>


            {/* Fill Out Form (Green) */}
            <a
              href="/home"
              target="_blank"
              rel="noopener noreferrer"
              className="
                min-w-[140px] rounded-md px-4 py-2 text-sm font-semibold 
                text-white shadow-neomorphic border border-[#16802d]
                bg-transparent 
                transition-all duration-300
                hover:scale-105
                hover:border-green-300 hover:bg-[#1ca63a] text-center
              "
            >
              Contact Us Form
            </a>

            {/* Check Out GitHub (Purple) */}
            <a
              href="https://github.com/YourRepoHere"
              target="_blank"
              rel="noopener noreferrer"
              className="
                min-w-[140px] rounded-md px-4 py-2 text-sm font-semibold
                text-white shadow-neomorphic border border-[#6A0DAD]
                bg-transparent
                transition-all duration-300
                hover:scale-105
                hover:border-purple-300 hover:bg-[#7923c2] text-center
              "
            >
              Check Out GitHub
            </a>

          </div>
        </section>

        {/* FIND TOOLS + TAG FILTER + TOOL LIBRARY */}
        <section className="bg-[#131819] rounded-xl p-6 shadow-neomorphic hover:shadow-neomorphic-inset hover:scale-[0.98] transition-transform">
          {/* SEARCH + TAGS in one row */}
          <div className="flex flex-col md:flex-row gap-6">

            {/* Search */}
            <div className="flex-1">
              <h2 className="text-2xl font-bold mb-2">Find Tools</h2>
              <div className="max-w-sm">
                <input
                  type="text"
                  placeholder="Search by name..."
                  className="w-full p-2 rounded bg-[#1F2427] text-white border border-[#333] text-sm"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            </div>

            {/* Tags */}
            <div className="flex-1">
              <h2 className="text-2xl font-bold mb-2">Tags</h2>
              <div className="flex flex-wrap gap-2">
                {filterTags.map((tag) => {
                  const isActive = selectedTag === tag.name;
                  return (
                    <button
                      key={tag.name}
                      onClick={() => handleTagClick(tag.name)}
                      className={`
                        inline-flex items-center gap-1 px-3 py-1 text-sm font-semibold 
                        rounded-md shadow-neomorphic 
                        transition-all duration-300
                        hover:shadow-neomorphic-inset hover:scale-105
                        ${isActive
                          ? 'bg-emerald-700 text-white'
                          : 'bg-[#1A1F22] text-gray-300 hover:bg-emerald-600 hover:text-white'
                        }
                      `}
                    >
                      {tag.icon}
                      {tag.label}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>

          {/* TOOL LIBRARY (3 columns) */}
          <div className="mt-6 border border-[#333] rounded-lg p-4">
            <h2 className="text-2xl font-bold mb-3">Tool Library</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {filteredTools.length > 0 ? (
                filteredTools.map((tool) => (
                  <div
                    key={tool.id}
                    className="bg-[#1F2427] rounded-md p-3 shadow-neomorphic 
                               transition-colors duration-300 hover:shadow-neomorphic-inset
                               relative"
                  >
                    {/* Title + Author */}
                    <h3 className="text-lg font-bold mb-1">{tool.name}</h3>
                    <p className="text-sm text-gray-400 mb-2">By {tool.author}</p>

                    {/* Description (full line) */}
                    <p className="text-sm opacity-75">{tool.description}</p>

                    {/* Tags display */}
                    {tool.tags && (
                      <div className="flex flex-wrap gap-1 mt-2">
                        {tool.tags.map((tag) => (
                          <span
                            key={tag}
                            className="
                              inline-block bg-[#0E1112] text-gray-300 px-2 py-1 
                              rounded hover:bg-emerald-600 hover:text-white 
                              transition-colors text-xs
                            "
                          >
                            {tag}
                          </span>
                        ))}
                      </div>
                    )}

                    {/* Bottom-right icons (GitHub + YouTube) bigger ~30px */}
                    <div className="flex items-center justify-end gap-4 mt-4">
                      {tool.github && (
                        <a
                          href={tool.github}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center text-[#b624db] hover:underline text-base"
                        >
                          <FaGithub size={30} />
                        </a>
                      )}
                      {tool.yt && (
                        <a
                          href={tool.yt}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center text-red-500 hover:underline text-base"
                        >
                          <FaYoutube size={30} />
                        </a>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center text-sm mt-4 col-span-3">
                  No matching tools found.
                </p>
              )}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Tool;
